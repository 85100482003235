import { Roles } from '@/auth/auth-context/enums/Roles';
import { useAuthContext } from '@/auth/useAuthContext';

import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { PATH_AFTER_LOGIN } from '../config';

export default function Index() {
  const { pathname, replace, prefetch } = useRouter();

  const { user } = useAuthContext();

  const userRole = user?.role as Roles;

  const pathAfterLogin =
    PATH_AFTER_LOGIN[userRole] ||
    user?.redirect_uri ||
    PATH_AFTER_LOGIN.default;
  useEffect(() => {
    if (pathname === '/') {
      replace(pathAfterLogin);
    }
  }, [pathname]);

  useEffect(() => {
    prefetch(pathAfterLogin);
  }, []);

  return null;
}
